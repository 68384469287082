import CoursesEnum from "@/enums/CoursesEnums";
import EventsEnums from "@/enums/EventsEnums";

export default [
    {
        path: '/notes',
        name: 'dashboard-notes-list',
        component: () => import('@/views/notes/notes-list/NotesList.vue'),
    },
    {
        path: '/notes/:id/edit',
        name: 'dashboard-edit-note',
        component: () => import('@/views/notes/notes-list/EditNote.vue'),
    },
]
