import EventsEnums from "@/enums/EventsEnums";

export default [
  {
    path: EventsEnums.MODULE_BASE_URL,
    name: 'events',
    component: () => import('@/views/events/events-list/EventsList'),
  },
  {
    path: EventsEnums.MODULE_BASE_URL+'/:id/edit',
    name: 'edit-event',
    component: () => import('@/views/events/events-list/EditEvent'),
  },
]
