import CoursesEnum from "@/enums/CoursesEnums";
import EventsEnums from "@/enums/EventsEnums";

export default [
    {
        path: '/flash-cards',
        name: 'dashboard-flash-cards-list',
        component: () => import('@/views/flash-cards/flash-cards-list/FlashCardsList.vue'),
    },
    {
        path: '/flash-cards/:id/edit',
        name: 'dashboard-edit-flash-card',
        component: () => import('@/views/flash-cards/flash-cards-list/EditFlashCard.vue'),
    },
]
