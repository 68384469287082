<template>
  <div>
    <p class="text-danger"
       v-if="requireImageFlag"> this field is required</p>
    <b-form-file
        v-model="file"
        multiple=""
        accept="image/*"
        :placeholder="title"
        drop-placeholder="Drop file here..."
        @change="uploadFile($event)"
    />
    <b-card>
      <div class="col-md-4" style="display: inline-block" v-for="(image, index) in images" :key="index">
        <b-img
            width="100"
            height="100"
            style="display: inline-block !important;padding-right: 3%"
            fluid
            class="mb-2"
            :src=image.url
        />
        <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            style="margin-top: 0!important;display: inline-block!important;"
            @click="removeImage(index, image)"
        >
          <feather-icon
              icon="XIcon"
              class="mr-25"
          />
          <span>Delete</span>
        </b-button>
      </div>
    </b-card>
    <b-overlay
        :show="show"
        no-wrap
    />
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import {BFormFile, BCardText, BImg, BCard, BButton,BOverlay} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "CourseImages",
  components: {
    BOverlay,
    BCardCode,
    BCardText,
    BFormFile,
    BImg,
    BCard,
    BButton


  },
  directives: {
    Ripple,
  },
  props: {
    'title': '',
    index: 0,
    default_place_holder_src: {
      default: "https://via.placeholder.com/100"
    },
    requireImageFlag: {
      default: false
    }
  },
  data() {
    return {
      file: null,
      show: false,
      file2: null,
      images: [],
      new_images: [],
      module_be_base_url: "/media/multi",
    }
  },
  created() {
    this.getCourseImages()
  },
  methods: {
    getCourseImages(){
      this.$http.get('get-course-images?course_id='+this.$route.params.id).then(res => {
        res.data.data.forEach(im => {
          this.images.push(im)
        })
      })
    },
    removeImage(index, image){
      this.images.splice(index, 1)
      this.$http.post('delete-course-image', {
        course_id : this.$route.params.id,
        image: index
      })
      this.$emit('multi_files_deleted_file', {index: index})
      console.log("here "+this.images)
    },
    uploadFile(event) {
      this.show = true
      let data = new FormData();
      data.append('name', 'my-picture');
      let sendData = [];
      let files = event.target.files
      for (var i = 0; i < files.length; i++){
        // sendData.push(event.target.files[i])
        data.append('media['+i+']', event.target.files[i]);
      }
      // data.append('media', sendData);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }

      this.$http.post(this.module_be_base_url,
          data,
          config
      )
          .then(res => {
            if (res.data.status_code === 1) {
              this.$emit('multi_files_uploaded', {media: res.data.data})
              // this.images = res.data.data
              this.showToast('success', 'top-center', res.data.message);
              this.new_images = []
              res.data.data.forEach(im => {
                this.images.push(im)
                this.new_images.push(im)
              })
              this.$http.post('single-course-images', {
                images: this.new_images,
                course_id: this.$route.params.id
              }).then(response => {
                this.show = false
              })
              this.show = false
              return 0;
            }
          }).catch(e => {
            console.log(e)
        this.showToast('danger', 'top-center', e.response.data.message);
      })
    },
    showToast(variant, position, message) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image uploaded successfully',
              icon: 'InfoIcon',
              text: message,
              variant,
            },
          },
          {
            position,
          })
    }
  }
}
</script>
