import CoursesEnum from "@/enums/CoursesEnums";

export default [
    {
        path: '/courses',
        name: 'dashboard-courses-list',
        component: () => import('@/views/courses/courses-list/CoursesList.vue'),
    },
    {
        path: '/courses/create',
        name: 'dashboard-courses-create',
        component: () => import('@/views/courses/courses-create/CoursesCreate.vue'),
    },
    {
        path: '/courses/:id/edit',
        name: 'dashboard-edit-course',
        component: () => import('@/views/courses/courses-edit/EditCourse.vue'),
    },
    {
        path: '/courses/:id/',
        name: 'dashboard-show-course',
        component: () => import('@/views/courses/courses-view/CourseView.vue'),
    },
    {
        path: '/course-reviews/:id/edit',
        name: 'dashboard-course-reviews',
        component: () => import('@/views/courses/courses-list/CoursesReviewsList'),
    },
    {
        path: '/course-categories/:id/',
        name: 'dashboard-course-categories',
        component: () => import('@/views/courses/courses-list/CoursesCategoriesList'),
    },
    {
        path: '/course-categories/:id/edit',
        name: 'dashboard-course-categories-edit',
        component: () => import('@/views/courses/courses-edit/EditCourseCategory'),
    },
    {
        path: '/host-course-request/',
        name: 'host-course-request',
        component: () => import('@/views/host-course-requests/requests-list/RequestsList'),
    },
]
