<template>
  <div class="border rounded p-2">
    <validation-observer
        ref="imageRules"
        tag="form"
    >
      <b-media
          no-body
          vertical-align="center"
          class="flex-column flex-md-row"
      >
        <b-media-aside>
          <b-img
              ref="refPreviewEl"
              :src="source"
              height="110"
              width="170"
              class="rounded mr-2 mb-1 mb-md-0"
          />
        </b-media-aside>
        <b-media-body>
          <small class="text-muted">Only images are accepted.</small>
          <b-card-text class="my-50">
            <b-link id="blog-image-text">

            </b-link>
          </b-card-text>
          <div class="d-inline-block">
<!--            <validation-provider-->
<!--                #default="{ errors }"-->
<!--                :name="'Image'"-->
<!--                rules="required"-->
<!--            >-->
              <b-form-file
                  :placeholder="title"
                  accept="image/*"
                  @change="uploadFile($event)"
              />
<!--              <small class="text-danger">{{ errors[0] }}</small>-->
<!--            </validation-provider>-->
          </div>
        </b-media-body>
      </b-media>
      <b-overlay
          :show="show"
          no-wrap
      />
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BFormFile, BCardText, BImg, BCard, BMedia,BMediaAside, BMediaBody, BLink,BOverlay} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "File",
  components: {
    ValidationObserver,
    ValidationProvider,
    BCardCode,
    BOverlay,
    BCardText,
    BFormFile,
    BImg,
    BCard,
    BMedia,
    BLink,
    BMediaBody,
    BMediaAside


  },
  props: {
    'title': '',
    index: 0,
    edit: false,
    default_place_holder_src: {},
    requireImageFlag: {
      default: false
    }
  },
  data() {
    return {
      show: false,
      module_be_base_url: "/media",
    }
  },
  methods: {
    uploadFile(event) {
      this.show = true
      let data = new FormData();
      data.append('name', 'my-picture');
      data.append('media', event.target.files[0]);

      let config = {
        header: {
          'Content-Type': 'image/png'
        }
      }

      this.$http.post(this.module_be_base_url,
          data,
          config
      )
          .then(res => {
            if (res.data.status_code === 1) {
              this.image = res.data.data.url
              this.$emit('file_uploaded', {media: res.data.data})
              this.$emit('thumbnail_uploaded', {
                index: this.index,
                src: res.data.data.url,
                id: res.data.data.id
              })
              this.show = false
              this.showToast('success', 'top-center', res.data.message);
              return 0;
            }
          }).catch(e => {
        this.showToast('danger', 'top-center', e.response.data.message);
      })
    },
    showToast(variant, position, message) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Image uploaded successfully',
              icon: 'InfoIcon',
              text: message,
              variant,
            },
          },
          {
            position,
          })
    }
  },
  computed: {
    source() {
      return this.default_place_holder_src || require("../../../src/assets"+'/images/placeholder.png');
    }
  }
}
</script>
